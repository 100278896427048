import React, { useRef, useEffect } from 'react'
import Image1 from '../images/products_er_01.png'
import Image2 from '../images/products_er_02.png'
import Image3 from '../images/products_er_03.png'

const ANIMATE_IN = {
  transform: 'translate3d(0, -50%, 0)',
  opacity: 1
}

const ANIMATE_OUT = {
  transform: 'translate3d(-25%, -50%, 0) scale(90%)',
  opacity: 0
}

const ErHeroImage = () => {

  const image1Ref = useRef(null)
  const image2Ref = useRef(null)
  const image3Ref = useRef(null)

  useEffect(() => {
    let timer1, timer2, timer3
    image2Ref.current.src = Image2
    image2Ref.current.onload = () => {
      Object.assign(image1Ref.current.style, ANIMATE_IN)
      timer1 = setTimeout(() => {
        image2Ref.current.style.opacity = 1
        Object.assign(image1Ref.current.style, ANIMATE_OUT)
      }, 2000)
      timer2 = setTimeout(() => {
        image2Ref.current.style.opacity = 0.2
        Object.assign(image3Ref.current.style, ANIMATE_IN)
      }, 3500)
      timer3 = setTimeout(() => {
        Object.assign(image3Ref.current.style, ANIMATE_OUT)
        image2Ref.current.style.opacity = 1
      }, 6000)
    }
    return () => {
      clearTimeout(timer1)
      clearTimeout(timer2)
      clearTimeout(timer3)
    }
  })

  return (
    <div className='relative overflow-hidden'>
      <div style={{ paddingBottom: '100%' }} />
      <img
        ref={image2Ref}
        alt=''
        style={{ opacity: 0 }}
        className='absolute w-full h-full top-0 left-0 transition duration-500'
      />
      <img
        ref={image1Ref}
        src={Image1}
        alt=''
        style={{ opacity: 0, transform: 'translate3d(25%, -50%, 0) scale(90%)' }}
        className='absolute top-1/2 w-full left-0 transition duration-500'
      />
      <img
        ref={image3Ref}
        src={Image3}
        alt=''
        style={{ opacity: 0, transform: 'translate3d(25%, -50%, 0) scale(90%)' }}
        className='absolute top-1/2 w-full left-0 transition duration-500'
      />
    </div>
  )
}

export default ErHeroImage
