
import React, { useEffect } from 'react'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import ProductsSubnav from '../../components/products_subnav'
import EsTeaser from '../../components/es_teaser'
import HeaderSpacer from '../../components/header_spacer'
import VideoModal from '../../components/video_modal'
import { StaticImage } from 'gatsby-plugin-image'
import Container from '../../components/container'
import Button from '../../components/button'
import { Link as GatsbyLink } from 'gatsby'
import DemoModal from '../../components/demo_modal'
import NumberedScroll from '../../components/numbered_scroll'
import ErHeroImage from '../../components/er_hero_image'
import FullyLoadedImage1 from '../../images/fullyloaded_er_VIN-specific-information.png'
import FullyLoadedImage2 from '../../images/fullyloaded_er_CRM-visibility.png'
import FullyLoadedImage3 from '../../images/fullyloaded_er_Customizable-messaging.png'
import EngagementIcon from '../../images/er_engagement_icon.svg'

const InternetLeadResponsePage = () => {

  useEffect(() => {
    let hasScrolled = false
    const observer = new IntersectionObserver(entries => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          window.addEventListener('scroll', () => {
            const target = entry.target
            const windowBottom = window.pageYOffset + window.innerHeight
            const elementTop = target.offsetTop
            const percentage = (windowBottom - elementTop) / target.offsetHeight
            target.style.setProperty('--scroll', percentage)
          })
        }
      })
      if (!hasScrolled) {
        hasScrolled = true
        window.dispatchEvent(new CustomEvent('scroll'))
      }
    })
    document.querySelectorAll('.is-animated').forEach(el => observer.observe(el))
  })

  return (
    <Layout activeNav={true}>
      <Seo
        title='Internet Lead Response'
        description='Our intelligent internet lead-response system immediately sends car buyers quality messages to help you engage them 24/7.'
      />
      <HeaderSpacer dark />
      <ProductsSubnav />
      <div className='flex flex-col justify-center relative' style={{ minHeight: 675 }}>
        <StaticImage
          src='../../images/er_hero_bg.jpg'
          alt=''
          placeholder='blurred'
          className='absolute inset-0'
        />
        <Container className='relative'>
          <div className='pt-4 md:pt-0 grid items-center md:gap-3 md:grid-cols-2 lg:gap-5 text-center md:text-left'>
            <div>
              <p className='text-gray uppercase font-semi-bold text-base tracking-wide leading-none'>
                Internet Lead Response
              </p>
              <h1 className='mt-0 font-semi-bold text-4vw tracking-tight' style={{ marginLeft: '-.08em' }}>
                Express Response<sup className='tm font-light'>™</sup>
              </h1>
              <p className='text-base sm:text-md lg:text-lg sm:leading-relaxed mb-1/2em'>
                Our intelligent internet lead-response system immediately sends car buyers quality messages to help you engage them 24/7. 
              </p>
              <p className='italic text-base sm:text-base lg:text-md text-gray'>
                Express Storefront Required
              </p>
              <div className='mt-3 sm:mt-4'>
                <DemoModal>
                  <Button
                    variant='dark'
                    minWidth={true}
                    children='Request Demo'
                  />
                </DemoModal>
              </div>
            </div>
            <div>
              <ErHeroImage />
            </div>
          </div>
        </Container>
      </div>
      <div className='Section pt-0'>
        <Container>
          <div className='grid gap-3 sm:gap-5 sm:grid-cols-2 lg:grid-cols-3 leading-relaxed'>
            <div>
              <h2 className='font-light text-xl sm:text-2xl mt-0'>
                <img
                  alt=''
                  src='https://media.roadster.com/dealersales/ER_Icon_1.svg'
                  height='82'
                  width='66'
                  className='block mb-2'
                  style={{ marginLeft: '-.2em' }}
                />
                Intelligent
              </h2>
              <p className='text-base sm:text-md mb-0'>
                Instantly send responses to internet leads that contain Express Store pricing and VIN-specific information that matches their inquiries.
              </p>
            </div>
            <div>
              <h2 className='font-light text-xl sm:text-2xl mt-0'>
                <img
                  alt=''
                  src='https://media.roadster.com/dealersales/ER_Icon_2.1.svg'
                  height='82'
                  width='66'
                  className='block mb-2'
                  style={{ marginLeft: '-.2em' }}
                />
                Actionable
              </h2>
              <p className='text-base sm:text-md mb-0'>
                Link customers directly to your Express Store, where they’ll see the exact vehicle(s) they’re interested in and can begin building their deal. Express Response emails generate 10x more engagement than generic CRM auto-responders.
              </p>
            </div>
            <div>
              <h2 className='font-light text-xl sm:text-2xl mt-0'>
                <img
                  alt=''
                  src='https://media.roadster.com/dealersales/ER_Icon_3.svg'
                  height='82'
                  width='66'
                  className='block mb-2'
                  style={{ marginLeft: '-.1em' }}
                />
                Efficient
              </h2>
              <p className='text-base sm:text-md mb-0'>
                Help your BDC and internet agents work smarter. Eliminate the time-consuming task of gathering information from multiple sources and ensure every lead gets a quality response every time.  
              </p>
            </div>
          </div>
        </Container>
      </div>
      <VideoModal videoId='456996516'>
        <button type='button' className='Section pt-0 sm:pb-0 relative sm:text-white'>
          <div className='relative'>
            <StaticImage
              src='../../images/ER_Video.jpg'
              alt=''
              placeholder='blurred'
            />
            <StaticImage
              alt='Play Video'
              src='../../images/icon-play.png'
              className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 sm:hidden'
              placeholder='tracedSVG'
              style={{ width: '15%' }}
            />
          </div>
          <div className='sm:absolute inset-0 flex flex-col justify-center'>
            <div>
              <Container>
                <div className='sm:flex'>
                  <div className='sm:size-1of2 md:size-3of8'>
                    <h2 className='text-xl md:text-2xl lg:text-3xl font-light'>
                      Seeing is Believing.
                    </h2>
                    <p className='text-md leading-relaxed max-w-measure-2 mb-0'>
                      In just two minutes, you'll learn how Express Response can increase customer engagement and efficiency at your store.
                    </p>
                    <div className='sm:hidden mt-3'>
                      <Button
                        as='span'
                        variant='dark'
                        children='Watch Video'
                      />
                    </div>
                  </div>
                  <div className='hidden sm:block flex-1 relative text-white'>
                    <StaticImage
                      alt='Play Video'
                      src='../../images/icon-play.png'
                      className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 size-2of10 md:size-4of10 hover:opacity-60 transition-opacity duration-300'
                      placeholder='tracedSVG'
                    />
                  </div>
                  <div className='hidden md:block size-3of8'></div>
                </div>
              </Container>
            </div>
          </div>
        </button>
      </VideoModal>
      <div className='Section'>
        <Container size='lg'>
          <h2 className='text-2xl sm:text-3xl font-light text-center mt-0'>
            Fully Loaded
          </h2>
          <p className='text-md text-center'>
            Express Response comes with everything needed to optimize your team’s internet lead management.
          </p>
          <NumberedScroll
            items={[
              {
                title: 'VIN-specific information',
                desc: 'Respond to customers’ queries with VIN-specific information that moves them down the purchase funnel faster.',
                image: FullyLoadedImage1
              },
              {
                title: 'All-inclusive automotive lead response',
                desc: 'Efficiently respond to internet leads from any source on one seamless platform.'
              },
              {
                title: 'New and used inventory',
                desc: 'Automatically follow up on car buyers’ interest in any piece of inventory. '
              },
              {
                title: 'CRM visibility',
                desc: 'Receive a notification in your CRM with a link to the email sent, so you can easily track internet-lead engagement and guide the car-buying journey.',
                image: FullyLoadedImage2
              },
              {
                title: 'Consistent price quotes',
                desc: 'Provide customers with pricing that matches what appears on your Express Store.'
              },
              {
                title: 'Engaging calls to action',
                desc: 'Encourage customers to start building deals with actionable messaging that links them to the appropriate SRP or VDP.'
              },
              {
                title: 'Customizable messaging',
                desc: 'Edit internet lead-response emails to sound like your unique brand.',
                image: FullyLoadedImage3
              },
              {
                title: 'Optional delayed response',
                desc: 'Control when internet lead-response emails are sent to customers and delay them if desired.'
              },
              {
                title: 'Performance reporting',
                desc: 'Track Express Response metrics — open rates, click-through rates, session length and actions taken — within Roadster Admin to measure car buyer engagement.'
              }
            ]}
          />
        </Container>
      </div>
      <div className='Section Section--stripe pb-3 text-center is-animated'>
        <Container>
          <h2 className='font-light text-xl sm:text-2xl md:text-3xl mt-0 mx-auto' style={{ maxWidth: '26em' }}>
            <img
              alt=''
              src={EngagementIcon}
              height='82'
              width='66'
              className='block mb-2 mx-auto'
            />
            Increase Engagement with Express Response
          </h2>
          <div className='mx-auto mt-4 mb-3' style={{ maxWidth: '82rem' }}>
            <div className='grid sm:grid-cols-2 gap-3 sm:gap-5 fadeInUp'>
              <div>
                <h3 className='text-lg uppercase mb-3 font-semi-bold'>
                  Open Rate
                </h3>
                <StaticImage
                  alt='Roadster 65% vs. Industry Average 12.6%'
                  src='../../images/ER_openrate.png'
                  placeholder='tracedSVG'
                />
              </div>
              <div>
                <h3 className='text-lg uppercase mb-3 font-semi-bold'>
                  Click-through Rate
                </h3>
                <StaticImage
                  alt='Roadster 22% vs. Industry Average 1.2%'
                  src='../../images/ER_clickrate.png'
                  placeholder='tracedSVG'
                />
              </div>
            </div>
          </div>
          <div className='my-4 sm:mt-5'>
            <DemoModal>
              <Button
                variant='dark'
                minWidth={true}
                children='Request Demo'
              />
            </DemoModal>
          </div>
          <div className='text-silver text-right'>
            Source: Campaign Monitor, “Ultimate Email Marketing Benchmarks for 2020: By Industry and Day”, 2020
          </div>
        </Container>
      </div>
      <div className='relative bg-dark-gray py-4'>
        <StaticImage
          alt=''
          src='../../images/customerstories_bg.jpg'
          placeholder='blurred'
          className='absolute inset-0'
        />
        <Container size='lg' className='relative'>
          <div className='text-white text-center'>
            <div className='font-semi-bold leading-none text-silver font-serif' style={{ fontSize: '6rem' }}>“</div>
            <div className='Testimonial' style={{ maxWidth: '30em' }}>
              Express Response has significantly increased our customer engagement. Not only are we able to immediately collect information from customers that otherwise would’ve taken 4 or 5 salespeople to gather, but our closing ratio is 16% because we can move our customers down the purchase funnel faster and more efficiently.
            </div>
            <div className='mt-3 text-md'>
              Greg Nalewaja, General Manager
            </div>
            <div className='text-md font-semi-bold'>
              Midlands Honda
            </div>
            <div className='flex items-center leading-none mb-4 mt-5'>
              <span className='flex-1 border-t border-gray' />
              <span className='mx-2 uppercase tracking-wide text-base sm:text-md font-semi-bold'>
                More Customer Stories
              </span>
              <span className='flex-1 border-t border-gray' />
            </div>
          </div>
          <div className='grid gap-3 sm:grid-cols-2 md:grid-cols-3 sm:gap-4'>
            <GatsbyLink
              to='/customer_stories/sunautogroup'
              className='group w-full flex flex-col bg-white hover:shadow-lg transition-shadow'>
              <div className='p-2 sm:p-3'>
                <div className='font-semi-bold text-base sm:text-md mb-1/4em'>
                  Sun Automotive Group
                </div>
                <div className='text-md sm:text-lg text-gray'>
                  Automotive Advisors are the single point of contact for the entire sales process.
                </div>
              </div>
              <div className='relative mt-auto'>
                <img
                  alt=''
                  loading='lazy'
                  src='https://media.roadster.com/stories/sunautogroup/thumb.jpg'
                  className='block w-full'
                /> 
                <div className='transition-opacity opacity-0 group-hover:opacity-100 bg-darken4 absolute inset-0 flex justify-center items-center text-white'>
                  <Button
                    as='span'
                    variant='outline'
                    minWidth={true}
                    children='View Story'
                  />
                </div>
              </div>
            </GatsbyLink>
            <GatsbyLink
              to='/customer_stories/audirocklin'
              className='group w-full flex flex-col bg-white hover:shadow-lg transition-shadow'>
              <div className='p-2 sm:p-3'>
                <div className='font-semi-bold text-base sm:text-md mb-1/4em'>
                  Audi Rocklin
                </div>
                <div className='text-md sm:text-lg text-gray'>
                  Transparent customer experiences that lead to higher gross and CSI.
                </div>
              </div>
              <div className='relative mt-auto'>
                <img
                  alt=''
                  loading='lazy'
                  src='https://media.roadster.com/stories/audirocklin/thumb.jpg'
                  className='block w-full'
                /> 
                <div className='transition-opacity opacity-0 group-hover:opacity-100 bg-darken4 absolute inset-0 flex justify-center items-center text-white'>
                  <Button
                    as='span'
                    variant='outline'
                    minWidth={true}
                    children='View Story'
                  />
                </div>
              </div>
            </GatsbyLink>
            <GatsbyLink
              to='/customer_stories/longotoyota'
              className='group w-full flex flex-col bg-white hover:shadow-lg transition-shadow'>
              <div className='p-2 sm:p-3'>
                <div className='font-semi-bold text-base sm:text-md mb-1/4em'>
                  Longo Toyota
                </div>
                <div className='text-md sm:text-lg text-gray'>
                  Digitizing every step of the sales process.
                </div>
              </div>
              <div className='relative mt-auto'>
                <img
                  alt=''
                  loading='lazy'
                  src='https://media.roadster.com/stories/longotoyota/thumb.jpg'
                  className='block w-full'
                /> 
                <div className='transition-opacity opacity-0 group-hover:opacity-100 bg-darken4 absolute inset-0 flex justify-center items-center text-white'>
                  <Button
                    as='span'
                    variant='outline'
                    minWidth={true}
                    children='View Story'
                  />
                </div>
              </div>
            </GatsbyLink>
          </div>
        </Container>
      </div>
      <EsTeaser />
    </Layout>
  )
}

export default InternetLeadResponsePage
